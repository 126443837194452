<template>
  <div>
    <i class="fas fa-user-tie"></i>
    <strong class="text-black" v-if="task_responsible">
      {{ task_responsible.name }}
      <span v-if="task_responsible && task_responsible.branch" class="font-light">
        ({{ task_responsible.branch.title }})
      </span>
      <span v-if="task_responsible.day_off_responsible" class="font-medium">
        (временный исполнитель: {{ task_responsible.day_off_responsible.name }})
      </span>
    </strong>
    <strong class="text-black" v-else>
      Нет исполнителя
    </strong>

    <hr class="mt-1 mb-1"/>
  </div>
</template>

<script>
export default {
  name: 'task-item-responsible-block',

  props: {
    task_responsible: {
      type: Object|null,
    }
  },
}
</script>

<style scoped>

</style>
